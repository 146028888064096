.image-overlay {
    position: absolute;
    bottom: 0;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    width: 100%;
    transition: .5s ease;
    color: white;
    font-size: 20px;
    height: 100%;
    text-align: center;
}
@media (max-width: 768px) {
    .container {
        max-width: 100%;
    }
}
.footer {
    clear: both;
    position: relative;
}

p {
    margin: 0.5rem;
}

h2 {
    font-weight: 600;
    font-size: 1rem;
}
.range.hover {
    fill: rgb(156, 173, 181);
    cursor:pointer;
  }
  .rangeName {
      cursor:pointer;
      transform-origin: center, center;
  }
  html,body {
      font-family: Montserrat;
  }
  .allRange {
     width:50%;
  }