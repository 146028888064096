.p-card {
  margin: 2rem;
}

.p-toast {
  max-width: calc(100vw - 20px);
}
@font-face {
  font-family: 'primeicons';
  font-display: auto;
  src: url('https://unpkg.com/primeicons@4.1.0/fonts/primeicons.eot');
  src: url('https://unpkg.com/primeicons@4.1.0/fonts/primeicons.eot?#iefix') format('embedded-opentype'), url('https://unpkg.com/primeicons@4.1.0/fonts/primeicons.ttf') format('truetype'), url('https://unpkg.com/primeicons@4.1.0/fonts/primeicons.woff') format('woff'), url('https://unpkg.com/primeicons@4.1.0/fonts/primeicons.svg?#primeicons') format('svg');
  font-weight: normal;
  font-style: normal;
}