footer {
  width: 100%;
  //float: left;
  //font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  //color: #574621;

  //position: absolute;
  //bottom: 0;
}

footer ul {
  //width: 1000px;
  text-align: center;
}

footer li {
  display: inline;
  margin: 1rem;
  list-style-type: none;
  line-height: 3rem;
  cursor: pointer;
}

.copyright {
  width: 100%;
  text-align: center;
}

.dialog-content {
  margin: 1rem;
}

.dialog-content h1 {
  font-weight: 700;
  font-size: 2rem;
}

.dialog-content h2 {
  font-weight: 700;
  font-size: 1.5rem;
}

